body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.reg_is_completed{
  color: #fff;
  background: #4FD1C5;
  display: inline-flex;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 8px;
  margin-bottom: 7px;
}

.register_not_completed{
  color: #fff;
  background: orange;
  display: inline-flex;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 8px;
  margin-bottom: 7px;
}

.flex_column > div{
  flex-wrap: wrap;
}

.flex_column > div > button{
  margin-bottom: 14px;
}